import { OrderJSONObject } from "@utils/Order/Order";

export interface OrderRequest {
  data: OrderJSONObject;
  locale: string;
  toEmail?: string;
  fromEmail?: string;
}

export interface ContactRequest {
  data: {
    name: string;
    email: string;
    phone?: string;
    company?: string;
    message: string;
  };
  locale: string;
}

export interface RegisterUserRequest {
  data: {
    id: number | undefined;
    name: string;
    email: string;
    phone?: string;
    company?: string;
    referrer?: string;
  };
  locale: string;
}

export interface PasswordResetRequest {
  data: {
    email: string;
  };
  locale: string;
}

export interface ProductRequest {
  data: {
    name: string;
    email: string;
    company?: string;
    message?: string;
    quantity: number;
    product:
      | {
          productNumber: string;
          elNumber: string;
          category: string | undefined;
          productName: string;
          productNameShort: string;
          quantity: number;
        }
      | undefined;
    configuration:
      | {
          productName: string;
          selections: {
            productNumber: string;
            elNumber?: string;
            productName: string;
            productNameShort?: string;
            quantity: number;
          }[];
        }
      | undefined;
  };
  locale: string;
}

export interface EmailHandler {
  sendProductRequestInternal(productRequest: ProductRequest): Promise<ApiResponse>;

  sendProductRequestExternal(productRequest: ProductRequest): Promise<ApiResponse>;

  sendContactRequestExternal(data: ContactRequest): Promise<ApiResponse>;

  sendContactRequestInternal(data: ContactRequest): Promise<ApiResponse>;

  sendUserRegisterExternal(data: RegisterUserRequest): Promise<ApiResponse>;

  sendUserRegisterInternal(data: RegisterUserRequest): Promise<ApiResponse>;
}

export enum ResponseStatus {
  Success = "SUCCESS",
  Error = "ERROR"
}

export interface ApiResponse {
  data: any;
  status: ResponseStatus;
  error: {
    message: string;
  };
}

export class EmailHandlerImpl implements EmailHandler {
  async sendProductRequestInternal(productRequest: ProductRequest): Promise<ApiResponse> {
    const response = await fetch(`/api/mail/request/product/internal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: productRequest.data,
        locale: productRequest.locale
      })
    });

    return await response.json();
  }

  async sendProductRequestExternal(productRequest: ProductRequest): Promise<ApiResponse> {
    const response = await fetch(`/api/mail/request/product/external`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: productRequest.data,
        locale: productRequest.locale
      })
    });

    return await response.json();
  }

  async sendContactRequestInternal(data: ContactRequest): Promise<ApiResponse> {
    const response = await fetch(`/api/mail/contact/internal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  }

  async sendContactRequestExternal(data: ContactRequest): Promise<ApiResponse> {
    const response = await fetch(`/api/mail/contact/external`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  }

  async sendUserRegisterInternal(data: RegisterUserRequest): Promise<ApiResponse> {
    const response = await fetch(`/api/mail/user/registered/internal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  }

  async sendUserRegisterExternal(data: RegisterUserRequest): Promise<ApiResponse> {
    const response = await fetch(`/api/mail/user/registered/external`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    return await response.json();
  }
}
